import { useState, useEffect, useRef } from "react";


export default function useComponentVisible(initialIsVisible) {
  const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible);
  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (event.target.className === 'navi-link') {
      setIsComponentVisible(false)
    }

    if (ref.current && !ref.current.contains(event.target)) {
      if (event.target.className !== 'hamburger-box') {
        setIsComponentVisible(false);
      }
    }

    if (event.target.className.includes('hamburger') && isComponentVisible) {
      setIsComponentVisible(true)
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  return {ref, isComponentVisible, setIsComponentVisible};
}