import React from 'react';
import './App.scss';
import Nav from "./components/Nav/Nav";
import Content from "./components/Content/Content";
import About from "./components/About/About";
import Projects from "./components/Projects/Projects";
import Contact from "./components/Contact/Contact";
import Footer from "./components/Footer/Footer";
import { Offer } from "./components/Offer/Offer";
import { Steps } from "./components/Steps/Steps";
import { CustomSlider } from "./components/CustomSlider/CustomSlider";

function App() {

  console.log(`Your screen resolution w: ${window.innerWidth}, h: ${window.innerHeight}`)

  return (
    <div className="App">
      <div className={"screen-whole"}>
        <Nav/>
        <Content/>
        <CustomSlider />
      </div>
      <div>
        <About/>
        <Offer/>
        <Steps/>
        <Projects/>
        <Contact/>
        <Footer/>
      </div>
    </div>
  );
}

export default App;
