import React, { useEffect } from 'react';
import './Content.scss';
import logo from '../img/logo-grafit.png'

const Content = () => {

  // useEffect(() => {
  //   console.log('width', window.innerWidth)
  //   console.log('height', window.innerHeight)
  // })

  return (<div className="Content" id={"content"}>
    <div className={"hero-logo hero-elem"}>
      <img src={logo} alt={"/"}/>
    </div>
    <div className={"hero-elem font-lato header-container"}>
      <h1 className={"greetings-animation"}>Ale Was urządzimy!</h1>
    </div>
  </div>)
};

Content.propTypes = {};

Content.defaultProps = {};

export default Content;
