import React from 'react'
import { StepElement } from "../StepElement/StepElement";
import './StepsRow.scss'

export const StepsRow = (props) => {
  let Elements = () => (
    <>
      {props.elements.map(element => (
        <StepElement key={element.numberToDisplay} numberToDisplay={element.numberToDisplay} header={element.header} textContent={element.textContent} />
      ))}
    </>
  )

  return(
    <div className={"stepsRow"}>
      <Elements />
    </div>
  )
}