import React from 'react'
import Call from '@material-ui/icons/Call'
import './Contact.scss'
import '../shared/shared-styles.scss'
import ContactTab from "./ContactTab";
import { Facebook, InsertChart, MailOutline } from "@material-ui/icons";
import { ReactComponent as Instagram } from '../shared/svg/instagram.svg'
import { ReactComponent as InstagramColor } from '../shared/svg/instagram-color.svg';
import { ParagraphWrapper } from "../ParagraphWrapper/ParagraphWrapper";

const Contact = () => {

  const MAIL_ADDRESS = "aleinteriors2021@gmail.com"
  const ALEK_NUMBER = "+48 669 230 815"
  const ILONA_NUMBER = "+48 506 591 617 "

  const Phone = () => (
    <div className={"flex-row items-center content-evenly text-shadow-s one-whole"}>
      <div onClick={() => window.location.href = `tel:${ILONA_NUMBER}`}
           className={"flex-column items-center"}>
        <p>Ilona</p>
        <p>{ILONA_NUMBER}</p>
      </div>
      <div onClick={() => window.location.href = `tel:${ALEK_NUMBER}`}
           className={"flex-column items-center"}>
        <p>Alek</p>
        <p>{ALEK_NUMBER}</p>
      </div>
    </div>
  )

  const Mail = () => (
    <div className={"flex-column items-center text-shadow-s"}>
      <p>e-mail</p>
      <p>{MAIL_ADDRESS}</p>
    </div>
  )

  const Insta = () => (
    <div className={"flex-column items-center text-shadow-s"}>
      <p>instagram</p>
      <p>@aleinteriors</p>
    </div>
  )

  const contactTabs = (
    <>
      <ContactTab icon={<Call fontSize={'inherit'}/>}
                  iconHover={<Call fontSize={'inherit'} className={"app-green"}/>}
                  content={<Phone/>}
      />
      <ContactTab icon={<MailOutline fontSize={'inherit'}/>}
                  iconHover={<MailOutline fontSize={'inherit'} className={"app-red"}/>}
                  onClickCallback={() => window.location.href = `mailto:${MAIL_ADDRESS}`}
                  content={<Mail/>}
      />
      <ContactTab icon={<Instagram/>}
                  onClickCallback={() => window.open("https://instagram.com/ale_interiors", "_blank")}
                  iconHover={<InstagramColor styles={{height: '24px', width: '24px'}}/>}
                  content={<Insta/>}
      />
    </>
  )

  const content = () =>
    <div className={"full-width one-whole-height flex-column justify-evenly"}>
      <div className={"padding-horizontal-xl text-align-center mobile-hide"}>
        <span>Zadzwoń do nas i umówmy się na spotkanie na miejscu inwestycji lub prześlij nam plany mieszkania na adres mailowy! <br/> Na tej podstawie wycenimy dla Ciebie projekt.</span>
      </div>
      <div className={"full-width content-center margin-vertical-auto flex-column one-half-height mobile-hide"}>
        <div className={"contact-tabs flex-container-row one-whole"}>
          {contactTabs}
        </div>
      </div>
      <div className={"content-center flex-column margin-top-xl full-hide"}>
        <div className={"contact-tabs one-whole margin-vertical-auto"}>
          {contactTabs}
        </div>
      </div>
    </div>

  return (
    <section id={"contact"}>
      <div className={"contact-container text-container-back mobile-margin-bottom-xl"}>
        <ParagraphWrapper className={"no-padding"} title={"KONTAKT"} wrappedComponent={content()}/>
      </div>
    </section>
  )
}

export default Contact