import React from "react";
import { CarouselProvider, Image, Slide, Slider } from "pure-react-carousel";
import 'pure-react-carousel/dist/react-carousel.es.css'
import './CustomSlider.scss'

export const CustomSlider = () => {
  return (
    <>
    <CarouselProvider className={"custom-slider mobile-hide"}
                      naturalSlideHeight={100}
                      naturalSlideWidth={125}
                      totalSlides={3}
                      infinite={true}
                      isIntrinsicHeight={true}
                      isPlaying={true}
    >
      <Slider>
        <Slide index={0}>
          <div className={"container"}/>
        </Slide>
        <Slide index={1}>
          <div className={"container2"}/>
        </Slide>
        <Slide index={2}>
          <div className={"container3"}/>
        </Slide>
      </Slider>
    </CarouselProvider>
      <div className={"full-hide flex-row custom-slider"}>
        <div className={"container"}/>
        <div className={"container2"}/>
        <div className={"container3"}/>
      </div>
      </>

      )
}