export const MEETING = {
  header: 'SPOTKANIE',
  content: 'Poznajmy się! Na spotkaniu omówimy Twoje potrzeby i oczekiwania. Porozmawiamy o zakresie i założeniach projektu.'
}

export const INVENTORY = {
  header: 'INWENTARYZACJA',
  content: 'Pracę zaczniemy od pomiarów na miejscu inwestycji lub skorzystamy z dokumentacji architektonicznej. Na tej podstawie przygotujemy ostateczną wycenę projektu.'
}

export const TWO_D = {
  header: 'UKŁAD FUNKCJONALNY 2D',
  content: 'Dla każdego pomieszczenia stworzymy koncepcję funkcjonalną z rozmieszczeniem najważniejszych mebli przedstawioną na rzucie 2D. Wspólnie wybierzemy najlepszy wariant.'
}

export const THREE_D = {
  header: 'WIZUALIZACJE 3D',
  content: 'Po zaakceptowaniu układu funkcjonalnego przejdziemy do wykonania trójwymiarowych wizualizacji projektowanych pomieszczeń. Na tym etapie dokonamy wyboru konkretnych materiałów, kolorystyki i mebli. W oparciu o Twoje wskazówki dopracujemy finalną wersję wizualizacji.'
}

export const DOCUMENTATION = {
  header: 'DOKUMENTACJA TECHNICZNA',
  content: 'Akceptacja wizualizacji pozwoli nam przejść do przygotowania szczegółowych rysunków technicznych, czyli podstawy realizacji projektu przez poszczególnych wykonawców. W skład dokumentacji wchodzi również spis materiałów oraz mebli.'
}

export const REALISATION = {
  header: 'REALIZACJA',
  content: 'W trakcie realizacji pozostajemy w stałym kontakcie z klientem. Pomożemy Ci wybrać ekipę wykonawczą oraz omówimy rysunki techniczne na miejscu inwestycji. Pełen nadzór nad pracami jest dodatkowo płatny.'
}