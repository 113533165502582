import React, { useState } from 'react'
import '../shared/shared-styles.scss'
import './ContactTab.scss'

const ContactTab = (props) => {

  const [isHovered, setIsHovered] = useState(false)

  const handleMouseEnter = (event) => {
    setIsHovered(true)
  }

  const handleMouseLeave = (event) => {
    setIsHovered(false)
  }

  let icon = (isHovered) ? props.iconHover : props.icon

  const RenderIcon = () => {
    return <div className={"svg-size-l"}>
      {icon}
    </div>
  }

  const handleClick = () => {
    if (props.onClickCallback) {
      props.onClickCallback()
    }
  }

  return (
    <>
      <div className={"one-third margin-auto flex-column items-center mobile-hide cursor-pointer"}
           onClick={handleClick}
           onMouseEnter={(event) => handleMouseEnter(event)}
           onMouseLeave={(event) => handleMouseLeave(event)}
      >
        <RenderIcon/>
        {props.content}
      </div>
      <div className={"contact-tab flex-column items-center full-hide margin-bottom-xl cursor-pointer"}
           onClick={handleClick}
           onMouseEnter={(event) => handleMouseEnter(event)}
           onMouseLeave={(event) => handleMouseLeave(event)}
      >
        <RenderIcon/>
        {props.content}
      </div>
    </>
  )
}

export default ContactTab