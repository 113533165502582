import React, { useEffect, useState } from 'react';
import './Nav.scss';
import logo from '../img/logo-grafit.png'
import { NavLink } from "./NavLink";
import useComponentVisible from "../shared/utils/useComponentVisible";
import insta from '../shared/svg/instagram-color.svg'

const Nav = () => {

  const {
    ref,
    isComponentVisible,
    setIsComponentVisible
  } = useComponentVisible(null);

  const navLinks = <>
    <NavLink url={"#"} text={"home"}/>
    <NavLink url={"#about"} text={"o nas"}/>
    <NavLink url={"#offer"} text={"oferta"}/>
    <NavLink url={"#steps"} text={"etapy projektu"}/>
    <NavLink url={"#projects"} text={"portfolio"}/>
    <NavLink url={"#contact"} text={"kontakt"}/>
    <NavLink className={"mobile-hide"} url={"https://instagram.com/ale_interiors"}
             text={<img style={{marginBottom: '-2px'}} height={17} src={insta}/>}/>
  </>

  const onClickHandler = () => {
    setIsComponentVisible(!isComponentVisible)
  }

  return (
    <div className={`Nav ${isComponentVisible ? 'fixed-top-left' : ''}`}>
      <nav className={"nav-container"}>
        <div className={"nav-logo"}>
          <img src={logo} alt={"/"}/>
        </div>
        <div className={"nav-links mobile-hide"}>
          {navLinks}
        </div>
        <div className={"burger-menu full-hide"}>
          <div className="hamburger hamburger--spin"
               aria-label="Menu" aria-controls="navigation" aria-expanded="false"
               onClick={() => onClickHandler()}>
      <span className="hamburger-box">
      <span className="hamburger-inner"/>
      </span>
          </div>
        </div>
        {isComponentVisible ? (
          <div ref={ref} className={"mobile-nav-elements-container"}>
            <div className={"mobile-nav-elements"}>
              {navLinks}
            </div>
          </div>) : null}
      </nav>
    </div>
  );
}


Nav.propTypes = {};

Nav.defaultProps = {};

export default Nav;
