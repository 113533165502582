import React from 'react'
import { ProjectElem } from "./ProjectElem";
import { ImageWrapper } from "./ImageWrapper";

export const ProjectRow = (props) => {
  let elements = () => (
    <>
      {props.elements.map(element => (
        <ImageWrapper title={element.title} wrappedComponent={<ProjectElem id={element.id} key={element.id} index={element.index} onElementClick={props.onElementClick} />} />
      ))}
    </>
  )
  return (
    <div className={"projects-row"}>
      {elements()}
    </div>
  )
}