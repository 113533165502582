import React, { useState } from "react";

export const ProjectElem = (props) => {

  return (
    <>
      <div className={"project-elem"}>
        <div id={props.id} className={"project-elem-background"} onClick={() => props.onElementClick(props.index)}/>
      </div>
    </>
  )
}