import React from 'react'

export const ABOUT_TITLE = "O NAS"
export const ABOUT_PARAGRAPH = <>
  <p>Cześć!</p>
  <p>Jesteśmy Ilona i Alek najbardziej nietypowy duet projektantów wnętrz - mama i syn.</p>
  <p>Nasze projekty to kompleksowe usługi, funkcjonalne rozwiązania, wygoda i praktyka użytkowania, które łączy styl
    oraz indywidualne podejście do klienta. Nie określają̨ nas sztywne ramy, schematy czy konkretny model działania.
    Tworzymy głównie na terenie Rzeszowa, jak również w całej Polsce i za granicą. Łączymy świeże, nowoczesne,
    szalone, jak młodość spojrzenie, z kobiecym, dojrzałym, opartym na wieloletnim doświadczeniu podejściem, które
    doskonale się uzupełniają.</p>
  <p>Każdy klient to oddzielna historia, której słuchamy z zaciekawieniem, by później móc ją właściwie opowiedzieć
    w stworzonym projekcie.</p>
  <p>Gotowi aby podzielić się z nami swoją historią?</p>
</>
export const OFFER_TITLE = "OFERTA"