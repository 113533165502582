import React from "react";
import { SquareDot } from "../SquareDot";
import './DotNumerator.scss'

export const DotNumerator = (props) => {
  return (
    <div className={"dot-numerator padding-right-s"}>
      <div className={"font-lato font-size-xxl shadowed-text flex-row dot-numerator-content"}>
        <div>{props.index}</div>
        <div>
          <SquareDot/>
        </div>
      </div>
    </div>
  )
}