import React from "react"
import { SquareDot } from "../shared/SquareDot";
import useWindowDimensions, { MOBILE_WIDTH } from "../shared/utils/useWindowDimensions";

export const ParagraphWrapper = (props) => {

  const { width } = useWindowDimensions()
  const isMobile = () => width <= MOBILE_WIDTH
  const additionalMarginBottom = (isMobile() ? 16 : 27) + 'px'

  const renderTitle = <div className={"paragraph-wrapper-header"}>
    <h3 className={"paragraph-wrapper-text"}>{props.title}</h3>
    <SquareDot size={1} styles={{margin: `auto auto ${additionalMarginBottom} auto`}}/>
  </div>

  return (
    <div className={"paragraph-wrapper"} style={{maxWidth: `${props.width || 60}%`}}>
      {renderTitle}
      <div className={"paragraph-wrapper-border paragraph-wrapper-content flex-row items-center paragraph-wrapper-text"}>
        {props.wrappedComponent}
      </div>
    </div>
  )
}