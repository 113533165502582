import React from 'react'
import './Footer.scss'
import licomix from '../img/licomix.png'
import veniDesign from '../img/veni-design.jpg'
import andbud from '../img/andbud.jpg'

const Footer = () => {

  const goToLicomix = () => {
    window.open('http://www.lico-mix.pl/', '_blank')
  }

  const goToVeniDesign = () => {
    window.open('https://venidesign.pl/', '_blank')
  }

  const goToAndbud = () => {
    window.open('https://and-bud.com.pl/', '_blank')
  }

  return (
    <footer className={'footer-container'}>
      <div className={"footer-content flex-column"}>
        <div className={"flex-row one-half-height items-center footer-elements-wrapper"}>
          <div className={"footer-element footer-text-secondary flex-row items-center one-whole"}>
            <div className={"flex-column items-center"}>
              <p>Partnerzy</p>
              <div className={"flex-row justify-evenly"} style={{width: '300px'}}>
                <div className={"cursor-pointer"} id={"licomix"} onClick={() => goToLicomix()}>
                  <img src={licomix} alt={''} width={75}/>
                </div>
                <div className={"cursor-pointer"} id={"veni-design"} onClick={() => goToVeniDesign()}>
                  <img src={veniDesign} alt={''} width={75}/>
                </div>
                <div className={"cursor-pointer align-self-center"} id={"andbud"} onClick={() => goToAndbud()}>
                  <img src={andbud} alt={''} width={75}/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={"flex-container-row one-half-height footer-elements-wrapper"}>
          <div className={"footer-element footer-text-secondary"}>
            <span>All projects are made by <strong>ale. interiors</strong></span>
          </div>
          <div className={"footer-element footer-text-secondary"}>
            <span>Design by <strong>@SD Oscar Kuźniar</strong></span>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer