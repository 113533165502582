import React, { useState } from 'react';
import './Projects.scss';
import '../shared/shared-styles.scss'
import { ProjectRow } from "./ProjectRow";
import { SquareDot } from "../shared/SquareDot";
import Lightbox from "react-image-lightbox";
import 'react-image-lightbox/style.css'
import kitchen from "./img/kuchnia.jpg"
import bathroom from "./img/lazienka.png"
import livingRoom from "./img/salon.jpg"
import bedroom from "./img/sypialnia.jpg"
import elevation from "./img/elewacja.jpg"
import projection from "./img/rzut.png"
import kuchnia0 from "./kitchen/kuchnia0.jpg"
import kuchnia1 from "./kitchen/kuchnia1.jpg"
import kuchnia2 from "./kitchen/kuchnia2.jpg"
import kuchnia3 from "./kitchen/kuchnia3.jpg"
import kuchnia4 from "./kitchen/kuchnia4.jpg"
import kuchnia5 from "./kitchen/kuchnia5.jpg"
import kuchnia6 from "./kitchen/kuchnia6.jpg"
import kuchnia7 from "./kitchen/kuchnia7.jpg"
import kuchnia8 from "./kitchen/kuchnia8.jpg"
import kuchnia9 from "./kitchen/kuchnia9.jpg"
import lazienka0 from "./bathroom/lazienka0.jpg"
import lazienka1 from "./bathroom/lazienka1.jpg"
import lazienka2 from "./bathroom/lazienka2.jpg"
import lazienka3 from "./bathroom/lazienka3.jpg"
import lazienka4 from "./bathroom/lazienka4.jpg"
import lazienka5 from "./bathroom/lazienka5.jpg"
import lazienka6 from "./bathroom/lazienka6.jpg"
import lazienka7 from "./bathroom/lazienka7.jpg"
import salon1 from "./livingroom/salon1.jpg"
import salon2 from "./livingroom/salon2.jpg"
import salon3 from "./livingroom/salon3.jpg"
import salon4 from "./livingroom/salon4.jpg"
import salon5 from "./livingroom/salon5.jpg"
import salon6 from "./livingroom/salon6.jpg"
import salon7 from "./livingroom/salon7.jpg"
import salon8 from "./livingroom/salon8.jpg"
import sypialnia1 from "./bedroom/sypialnia1.jpg"
import sypialnia2 from "./bedroom/sypialnia2.jpg"


const Projects = () => {
  const firstRowElements = [
    {id: "kuchnia", title: "Kuchnia", index: 0},
    {id: "lazienka", title: "Łazienka", index: 11},
    {id: "salon", title: "Salon", index: 20}
  ]
  const secondRowElements = [
    {id: "sypialnia", title: "Sypialnia", index: 29},
    {id: "elewacja", title: "Elewacje", index: 32},
    {id: "rzut", title: "Rzuty 2D/3D", index: 33}
  ]

  const [isOpen, setOpen] = useState(false)
  const [photoIndex, setPhotoIndex] = useState(0)

  const kitchenArr = [
    kuchnia0, kuchnia1, kuchnia2, kuchnia3, kuchnia4, kuchnia5, kuchnia6, kuchnia7, kuchnia8, kuchnia9
  ]

  const bathroomArr = [
    lazienka0, lazienka1, lazienka2, lazienka3, lazienka4, lazienka5, lazienka6, lazienka7
  ]

  const livingRoomArr = [
    salon1, salon2, salon3, salon4, salon5, salon6, salon7, salon8
  ]

  const bedroomArr = [
    sypialnia1, sypialnia2
  ]

  const images = [
    kitchen,
    ...kitchenArr,
    bathroom,
    ...bathroomArr,
    livingRoom,
    ...livingRoomArr,
    bedroom,
    ...bedroomArr,
    elevation,
    projection
  ]

  const handleOnClick = (index) => {
    setPhotoIndex(index)
    setOpen(true)
  }

  return (
    <section id={"projects"} className="Projects">
      {isOpen && <Lightbox mainSrc={images[photoIndex]}
                           nextSrc={images[(photoIndex + 1) % images.length]}
                           prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                           onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
                           onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
                           onCloseRequest={() => setOpen(false)}
      />
      }
      <div className={"one-fourth-screen text-container-back mobile-hide"}>
        <div className={"sixty-percent-width content-center margin-vertical-auto flex-column one-whole-height"}>
          <div className={"flex-row height-l"}>
            <div className={"flex-row margin-auto title font-size-xxxl mobile-hide"}>
              <div className={"flex-row align-baseline"}>
                <h3 className={"paragraph-wrapper-text"}>PORTFOLIO</h3>
                <SquareDot size={1} styles={{margin: "auto auto 125px auto"}}/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={"one-eight-screen text-container-back full-hide flex-column"}>
        <div className={"flex-row title font-size-xxl mobile-projects-title"}>
          <div className={"flex-row"}>
            <h3 className={"paragraph-wrapper-text"}>PORTFOLIO</h3>
            <SquareDot size={1} styles={{margin: "auto auto 75px auto"}}/>
          </div>
        </div>
      </div>
      <div className={"text-container-back"}>
        <ProjectRow elements={firstRowElements} onElementClick={handleOnClick}/>
        <ProjectRow elements={secondRowElements} onElementClick={handleOnClick}/>
      </div>
    </section>
  )
}

Projects.propTypes = {};

Projects.defaultProps = {};

export default Projects;
