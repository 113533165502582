import React from 'react'
import './StepElement.scss'
import { DotNumerator } from "../../shared/DotNumerator/DotNumerator";

export const StepElement = (props) => {
  return (
    <div className={"stepElement flex-column"}>
      <div className={"flex-row align-baseline"}>
        <DotNumerator index={props.numberToDisplay}/>
        <p className={'font-size-m font-bold text-shadow-s text-align-justify stepElementHeader'}>{props.header}</p>
      </div>
      <div className={"stepElementContent"}>
        <p className={'font-size-m'}>{props.textContent}</p>
      </div>
    </div>
  )
}