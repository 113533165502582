import React from "react"

export const SquareDot = (props) => {

  const size = props.size + 'rem'
  const margin = props.styles?.margin || 'auto'

  const styles = {
    ...props.styles,
    minWidth: '10px',
    minHeight: '10px',
    height: size,
    width: size,
    margin: margin,
    marginLeft: '5px',
    // background: 'rgba(30, 30, 30, 0.8)',
    background: 'black',
    boxShadow: '2px 1px 2px rgba(10, 10, 10, 0.7)'
  }

  return (
    <div style={styles}>
    </div>
  )
}