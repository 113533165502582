import React from "react";
import { OFFER_TITLE } from "../About/aboutContent";
import { ParagraphWrapper } from "../ParagraphWrapper/ParagraphWrapper";
import "./Offer.scss"
import visualisation from '../shared/svg/wizualizacje.svg'
import consultations from '../shared/svg/konsultacje.svg'
import projection from '../shared/svg/projections.svg'
import furniture from '../shared/svg/projekt_mebli.svg'
import interiorProj from '../shared/svg/kompleksowe_projekty_wnetrz2.svg'
import funcProj from '../shared/svg/projekt_funkcjonalny.svg'
import onlineProj from '../shared/svg/projekt_zdalny.svg'
import elevations from '../shared/svg/elewacje.svg'
import electricity from '../shared/svg/elektryka_hydraulika.svg'
import apartment from '../shared/svg/odbiory_mieszkan.svg'
import { OfferElement } from "./OfferElement";

export const Offer = () => {

  const disclaimerText = "Nasze studio współpracuje z zaufanymi ekipami wykończeniowymi oraz specjalistami tj. stolarze, elektrycy, hydraulicy."
  const disclaimer = <p className={"margin-vertical-auto margin-top-xl text-no-shadow text-align-center font-bold letter-spacing-s"}>{disclaimerText}</p>

  const firstContentRow = <>
    <OfferElement icon={interiorProj} text={"KOMPLEKSOWE PROJEKTY WNĘTRZ"} />
    <OfferElement icon={visualisation} text={"WIZUALIZACJE 3D"} />
    <OfferElement icon={funcProj} text={"PROJEKTY FUNKCJONALNE / KONCEPCYJNE"} />
  </>

  const secondContentRow = <>
    <OfferElement icon={consultations} text={"KONSULTACJE"} />
    <OfferElement icon={onlineProj} text={"PROJEKTY ONLINE/ZDALNE"} />
    <OfferElement icon={elevations} text={"PROJEKTY ELEWACJI"} />
    <OfferElement icon={electricity} text={"PROJEKTY ELEKTRYKI I HYDRAULIKI"} />
  </>

  const thirdContentRow = <>
    <OfferElement icon={furniture} text={"PROJEKTY MEBLI"} />
    <OfferElement icon={projection} text={"RZUTY 2D/3D MIESZKAŃ POD SPRZEDAŻ"} />
    <OfferElement icon={apartment} text={"POMOC W ODBIORZE MIESZKAŃ"} />
  </>

  const content = () => (
    <div className={"offer-content one-whole flex-column font-size-m margin-vertical-auto"}>
      <div className={"flex-row justify-around one-whole"}>
        {firstContentRow}
      </div>
      <div className={"flex-row justify-around one-whole"}>
        {secondContentRow}
      </div>
      <div className={"flex-row justify-around one-whole"}>
        {thirdContentRow}
      </div>
      <div className={"flex-column justify-around one-whole"}>
        {disclaimer}
      </div>
    </div>
  )

  const mobileContent = () => (
    <div className={"offer-content self-center margin-vertical-auto flex-column font-size-m padding-top-s padding-bottom-m offer-mobile-content"}>
      {firstContentRow}
      {secondContentRow}
      {thirdContentRow}
      {disclaimer}
    </div>

  )
  return (
    <section className="Offer" id={"offer"}>
      <div className={"three-quarters-screen text-container-back mobile-hide"}>
        <ParagraphWrapper title={OFFER_TITLE} wrappedComponent={content()} width={70} />
      </div>
      <div className={"mobile-two-heights text-container-back full-hide"}>
        <ParagraphWrapper title={OFFER_TITLE} wrappedComponent={mobileContent()}/>
      </div>
      <div className={"half-screen offer-img-container"} />
    </section>
  )
}