import React from 'react';
import FormatPaintOutlined from '@material-ui/icons/FormatPaintOutlined'
import './About.scss';
import '../shared/shared-styles.scss'
import { ABOUT_PARAGRAPH, ABOUT_TITLE } from "./aboutContent";
import { ParagraphWrapper } from "../ParagraphWrapper/ParagraphWrapper";
import "../ParagraphWrapper/ParagraphWrapper.scss"

const About = () => {

  const content = <div className={"text-container-paragraph padding-m about-content"}>
    <div className={"text-align-justify"}>
      {ABOUT_PARAGRAPH}
    </div>
    <FormatPaintOutlined style={{...iconStyles, margin: "auto"}}/>
  </div>

  const mobileContent = <div className={"text-container-paragraph text-align-justify about-content flex-column"}>
    <div className={"padding-horizontal-xs"}>
      {ABOUT_PARAGRAPH}
    </div>
    <FormatPaintOutlined style={{...iconStyles, margin: "auto"}}/>
  </div>

  return (
    <section className="About" id={"about"}>
      <div className={"three-quarters-screen text-container-back mobile-hide"}>
        <ParagraphWrapper title={ABOUT_TITLE} wrappedComponent={content} width={70}/>
      </div>
      <div className={"mobile-one-and-half text-container-back full-hide"}>
        <ParagraphWrapper title={ABOUT_TITLE} wrappedComponent={mobileContent} />
      </div>
      <div className={"half-screen about-img-container"}>

      </div>
    </section>
  )
}

const iconStyles = {
  fontSize: '15rem',
};

About.propTypes = {};

About.defaultProps = {};

export default About;
