import React from 'react'
import { SquareDot } from "../shared/SquareDot";

export const NavLink = (props) => {

  const link = (props.url).startsWith("http") ? props.url : "/" + props.url

  return (
    <div className={`nav-link ${props.className || ''}`}>
      <div className={"nav-link-container"}>
        <a className={"navi-link"} href={link}>{props.text}</a>
        <SquareDot size={0.66} />
      </div>
    </div>
  )
}