import React from "react";
import { SquareDot } from "../shared/SquareDot";
import "./ImageWrapper.scss"
import "./Projects.scss"

export const ImageWrapper = (props) => {
  const renderTitle = <div className={"image-wrapper-header"}>
    <div className={"flex-row"}>
      <h3 className={"image-wrapper-text"}>{props.title}</h3>
      <SquareDot size={0.6}/>
    </div>
  </div>

  const side = () => (window.innerWidth <= 768) ? window.innerWidth - 30 : window.innerWidth / 3.8

  return (
    <>
      <div className={"image-wrapper"} style={{width: side(), height: side(), maxHeight: '100%'}}>
        <div className={"image-wrapper-border image-wrapper-content"}>
          {props.wrappedComponent}
          {renderTitle}
        </div>
      </div>
    </>
  )
}