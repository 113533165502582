import React from "react"
import { ParagraphWrapper } from "../ParagraphWrapper/ParagraphWrapper";
import "./Steps.scss"
import { StepsRow } from "./StepRow/StepsRow";
import { DOCUMENTATION, INVENTORY, MEETING, REALISATION, THREE_D, TWO_D } from "./content";
import useWindowDimensions, { LAP_WIDTH } from "../shared/utils/useWindowDimensions";

export const Steps = () => {

  const firstRowElements = [
    {
      numberToDisplay: 1,
      header: MEETING.header,
      textContent: MEETING.content
    },
    {
      numberToDisplay: 2,
      header: INVENTORY.header,
      textContent: INVENTORY.content
    },
    {
      numberToDisplay: 3,
      header: TWO_D.header,
      textContent: TWO_D.content
    }
  ]

  const secondRowElements = [
    {
      numberToDisplay: 4,
      header: THREE_D.header,
      textContent: THREE_D.content
    },
    {
      numberToDisplay: 5,
      header: DOCUMENTATION.header,
      textContent: DOCUMENTATION.content
    },
    {
      numberToDisplay: 6,
      header: REALISATION.header,
      textContent: REALISATION.content
    }
  ]

  const { height, width } = useWindowDimensions()

  const isSmallerThanLap = () => width <= LAP_WIDTH

  const content = <div className={"padding-bottom-m padding-top-s steps-mobile-content"}>
    <StepsRow elements={firstRowElements} />
    <StepsRow elements={secondRowElements} />
  </div>

  const title = isSmallerThanLap() ? 'ETAPY' : 'ETAPY PROJEKTU'

  return (
    <section className="Steps" id={"steps"}>
      <div className={"three-quarters-screen text-container-back steps-background"}>
        <div className={"steps-content-wrapper"}>
          <ParagraphWrapper className={"no-padding"} title={title} wrappedComponent={content}/>
        </div>
      </div>
      <div className={"half-screen steps-img-container mobile-hide"} />
      <div className={"three-quarters-screen steps-img-container full-hide"} />
    </section>
  )
}